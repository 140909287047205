export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
    // role: "Admin",
  },
  {
    title: "Packages",
    icon: "PackageIcon",
    // role: "Admin",
    children: [
      {
        title: "All Packages",
        route: "packages",
      },
    ],
  },
  {
    title: "Gallery",
    icon: "ImageIcon",
    // role: "Admin",
    children: [
      {
        title: "Gallery",
        route: "gallery",
      },
    ],
  },
];
